import axios from 'axios'

axios.defaults.timeout = 600000;

axios.interceptors.request.use(
    config => {
        // const token = getToken()
        // const user = getCookie('user')
        // if (token) {
        //     config.headers.token = `${token}`
        //     config.headers.user = `${user}`
        // }
        // 在发送请求之前做一些事情
        return config
    },
    error => {
        // 请求错误
        return Promise.reject(error)
    }
)
// response interceptor
axios.interceptors.response.use(
    response => {
        // if (response.data.status === 401) {
        //     router.push('Login')
        // }
        return response
    },
    error => {
        /* 在这里设置token过期的跳转*/
        return  Promise.reject(error)
    })
export default axios;